import React from 'react';

import { Router } from 'react-router-dom';
import { createBrowserHistory } from 'history';

import jwt_decode from 'jwt-decode';

import ErrorBoundary from '@/ErrorBoundary';

import AuthProvider, { AuthConsumer, TAuthRetriveUserCallback } from '@/providers/AuthProvider';
import LocaleProvider from '@/providers/LocaleProvider';

/**
 * Routers
 */
import NotAuthRouter from '../NotAuthRouter';
import AuthRouter from '../AuthRouter';

import translations from '@/translations';

import { initRefresh } from '@/helpers/refresh';
import { CampaignProvider } from '@/providers/AuthProvider/campaignInfoProvider';
import { LaboratoryProvider } from '@/providers/AuthProvider/labInfoProvider';

/**
 * Get router's history
 */
export const history = createBrowserHistory();

/**
 * @param token stored on cookie allow re-authentification.
 * @returns User's informations store on authentification.
 */
const onRetriveUserFromCookie: TAuthRetriveUserCallback = (token) => Promise.resolve(jwt_decode(token));

const AppRouter: React.FC = () => {
  const [{ action, location }, setState] = React.useState({
    action: history.action,
    location: history.location,
  });

  React.useEffect(() => {
    window.history.replaceState({}, document.title);
  }, [location]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  React.useLayoutEffect(() => history.listen(setState), [history]);

  React.useEffect(() => {
    initRefresh();
  }, []);

  return (
    <Router location={location} navigationType={action} navigator={history}>
      <CampaignProvider>
        <LaboratoryProvider>
          <LocaleProvider translations={translations}>
            <AuthProvider onRetriveUserFromCookie={onRetriveUserFromCookie}>
              <ErrorBoundary>
                <AuthConsumer>{({ user }) => (user === null ? <NotAuthRouter /> : <AuthRouter />)}</AuthConsumer>
              </ErrorBoundary>
            </AuthProvider>
          </LocaleProvider>
        </LaboratoryProvider>
      </CampaignProvider>
    </Router>
  );
};

export default AppRouter;
