import React from "react";

import { TAuthRefreshTokenOptions } from '../../helpers/API/interfaces';

export enum EUserRole {
    /**
     * User has user's rights
     */
    USER = 'ROLE_USER',

    /**
     * User's an administator.
     */
    ADMIN = 'ROLE_ADMIN',

    /**
     * User's an animator
     */
    ANIMATOR = 'ROLE_LABORATORY_ANIMATOR'
};

export type TUser = {
    /**
     * User's name (mainly his email)
     */
    username: string;

    /**
     * Rights given to the user.
     */
    roles: EUserRole[];
}

export interface IAuthState {
    /**
     * User object
     */
    user: TUser | null;
}

export enum EAuthActionType {
    /**
     * Set User on provider
     */
    Set = 'AUTH_SET_USER',

    /**
     * Update User on provider
     */
    Edit = 'AUTH_EDIT_USER',
}

export type TAuthAction = {
    /**
     * type of reducer's action
     */
    type: EAuthActionType;

    /**
     * reducer's action parameter need to set or update user
     */
     user: TUser | null;
}

export type TAuthReducer = (prevState: IAuthState, action: TAuthAction) => IAuthState;

export enum EAuthType {
    /**
     * Setup basic authentification
     */
    Basic = 'Basic',

    /**
     * Setup bearer token authentification
     */
    BToken = 'Bearer',
}

export type TAuthTypeBasicValues = {
    /**
     * Basic authentification's username
     */
    username: string;

    /**
     * Basic authentification's password
     */
    password: string;
};

export type TAuthLoginOptions = {
    /**
     * Type of login authentification. Default is Bearer token.
     */
    type?: EAuthType;

    /**
     * Refresh token to store on cookie.
     */
    refreshToken?: string;
};

export type TAuthTypeBTokenValues = string;

export type TAuthTypeValues = TAuthTypeBasicValues | TAuthTypeBTokenValues;

export type TLoginCallback = (values: TAuthTypeValues, user: TUser, options?: TAuthLoginOptions) => void;

export type TLogoutCallback = () => void;

export type TEditUserCallback = (user: TUser) => void;

export interface IAuthProps extends IAuthState {
    /**
     * Login callback use to setup open new connection
     */
    login: TLoginCallback,

    /**
     * Logout callback use to setup close current connection
     */
    logout: TLogoutCallback,

    /**
     * Callback use to update current user datas.
     */
    editUser: TEditUserCallback,
}

export type TAuthRetriveUserCallback = (token: string) => Promise<TUser>;

export type TAuthProps = {
    /**
     * Callback use to retrieve current user from token stored on cookie.
     */
    onRetriveUserFromCookie: TAuthRetriveUserCallback;

    /**
     * Name of expiration field use by the JWT token, default 'exp'
     */
    tokenExpirationField?: string;

    /**
     * Shift between the way Timestamps is interpreting.
     * PHP use seconds as based while javascript is on milliseconds.
     * default (1000).
     */
    tokenTimestampShift?: number;

    /**
     * Component render during loading.
     */
    renderLoading?: React.ReactElement<any, any> | null;

    /**
     * Options use to configure refresh token.
     */
    refreshTokenOptions?: TAuthRefreshTokenOptions;

    /**
     * React's children
     */
    children: React.ReactNode | React.ReactNode[];
}