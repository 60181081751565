import React from 'react';

import { LocaleContext } from '@/providers/LocaleProvider';

import HTMLRender from '@/components/HTMLRender';

import { TLocaleTextProps } from './interfaces';

const LocaleText: React.FC<TLocaleTextProps> = ({ isHtml = false, plural = false, params = [], children }) => {
    const { getTranslation } = React.useContext(LocaleContext);

    const localeText = React.useMemo(() => getTranslation((children as string).trim(), { plural, params }),
    [getTranslation, children, plural, params]);
    
    return isHtml ? <HTMLRender>{localeText}</HTMLRender> : <>{localeText}</>;
};

export default LocaleText;