import listCampaign from '@/helpers/API/requests/campaignList';
import { TCampaignDataResponse, TBrandDataResponse } from '@/helpers/API/requests/interfaces/campaignList';
import React from 'react';

export const useGetAnimatorData = () => {
  const [currentCampaignData, setCurrentCampaignData] = React.useState<TCampaignDataResponse[]>([]);
  const [futureCampaignData, setFutureCampaignData] = React.useState<TCampaignDataResponse[]>([]);
  const [brandsData, setBrandsData] = React.useState<TBrandDataResponse[]>([]);
  const [isLoading, setIsLoading] = React.useState(false);

  React.useEffect(() => {
    setIsLoading(true);

    listCampaign()
      .then((data) => {
        setCurrentCampaignData(data.currentCampaigns);
        setFutureCampaignData(data.futureCampaigns);
        setBrandsData(data.laboratoryBrands);
        setIsLoading(false);
      })
      .catch((data) => {
        setIsLoading(false);
      });
  }, []);

  return { currentCampaignData, futureCampaignData, brandsData, isLoading };
};
